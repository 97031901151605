import React from "react";

export const DropdownSuccessIcon = ({ className = "" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="11" cy="12" rx="11" ry="12" fill="#824C88" />
      <path
        d="M9.12836 14.2675L6.38879 11.3348C6.29058 11.2297 6.17398 11.1464 6.04566 11.0895C5.91734 11.0327 5.7798 11.0034 5.64091 11.0034C5.50202 11.0034 5.36448 11.0327 5.23616 11.0895C5.10784 11.1464 4.99125 11.2297 4.89303 11.3348C4.69468 11.547 4.58325 11.8348 4.58325 12.1349C4.58325 12.435 4.69468 12.7228 4.89303 12.935L8.38137 16.6668C8.4793 16.7724 8.59581 16.8562 8.72417 16.9134C8.85252 16.9706 8.9902 17 9.12924 17C9.26829 17 9.40596 16.9706 9.53432 16.9134C9.66268 16.8562 9.77918 16.7724 9.87712 16.6668L17.1004 8.93741C17.2 8.83278 17.2791 8.70811 17.3334 8.57058C17.3877 8.43305 17.4159 8.28538 17.4166 8.13611C17.4172 7.98683 17.3902 7.83889 17.3372 7.70083C17.2841 7.56277 17.206 7.43731 17.1074 7.33169C17.0088 7.22607 16.8916 7.14239 16.7626 7.08546C16.6336 7.02853 16.4954 6.99949 16.3558 7.00001C16.2163 7.00053 16.0782 7.03059 15.9496 7.08848C15.821 7.14636 15.7044 7.23092 15.6064 7.33726L9.12836 14.2675Z"
        fill="white"
      />
    </svg>
  );
};
