import React from "react";

export const AddVehicleProfileIcon = ({ className = "" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 53 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="53" height="48" rx="4" fill="#4C0055" />
      <path
        d="M26.5039 33C26.2708 33 26.0473 32.9074 25.8824 32.7426C25.7176 32.5777 25.625 32.3542 25.625 32.1211V15.8789C25.625 15.6458 25.7176 15.4223 25.8824 15.2574C26.0473 15.0926 26.2708 15 26.5039 15C26.737 15 26.9606 15.0926 27.1254 15.2574C27.2902 15.4223 27.3828 15.6458 27.3828 15.8789V32.1211C27.3828 32.3542 27.2902 32.5777 27.1254 32.7426C26.9606 32.9074 26.737 33 26.5039 33Z"
        fill="white"
      />
      <path
        d="M34.6211 24.8789H18.3789C18.1458 24.8789 17.9223 24.7863 17.7574 24.6215C17.5926 24.4567 17.5 24.2331 17.5 24C17.5 23.7669 17.5926 23.5433 17.7574 23.3785C17.9223 23.2137 18.1458 23.1211 18.3789 23.1211H34.6211C34.8542 23.1211 35.0777 23.2137 35.2426 23.3785C35.4074 23.5433 35.5 23.7669 35.5 24C35.5 24.2331 35.4074 24.4567 35.2426 24.6215C35.0777 24.7863 34.8542 24.8789 34.6211 24.8789Z"
        fill="white"
      />
    </svg>
  );
};
