import React from "react";

const ModalBody = (props) => {
  return (
    <div className="p-4 md:p-6 space-y-6 border-b dark:border-gray-600">
      {props.children}
    </div>
  );
};

export { ModalBody };
