export const SearchVehicleProfileIcon = ({ className = "" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 53 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.3323 30.5547L31.2733 27.5047C32.2603 26.2473 32.7958 24.6946 32.7938 23.0961C32.7938 21.6726 32.3717 20.281 31.5808 19.0973C30.7899 17.9137 29.6658 16.9911 28.3506 16.4463C27.0353 15.9016 25.5881 15.759 24.1919 16.0367C22.7957 16.3145 21.5132 17 20.5066 18.0066C19.5 19.0132 18.8145 20.2957 18.5367 21.6919C18.259 23.0881 18.4016 24.5353 18.9463 25.8506C19.4911 27.1658 20.4137 28.2899 21.5973 29.0808C22.781 29.8717 24.1726 30.2938 25.5961 30.2938C27.1946 30.2958 28.7473 29.7603 30.0047 28.7733L33.0547 31.8323C33.1384 31.9166 33.2379 31.9836 33.3475 32.0292C33.4571 32.0749 33.5747 32.0984 33.6935 32.0984C33.8123 32.0984 33.9299 32.0749 34.0395 32.0292C34.1492 31.9836 34.2487 31.9166 34.3323 31.8323C34.4166 31.7487 34.4836 31.6492 34.5292 31.5395C34.5749 31.4299 34.5984 31.3123 34.5984 31.1935C34.5984 31.0747 34.5749 30.9571 34.5292 30.8475C34.4836 30.7379 34.4166 30.6384 34.3323 30.5547ZM20.1979 23.0961C20.1979 22.0284 20.5145 20.9847 21.1076 20.097C21.7008 19.2093 22.5439 18.5174 23.5303 18.1088C24.5167 17.7002 25.6021 17.5933 26.6493 17.8016C27.6964 18.0099 28.6583 18.524 29.4133 19.279C30.1682 20.0339 30.6824 20.9958 30.8907 22.043C31.0989 23.0901 30.992 24.1755 30.5835 25.1619C30.1749 26.1483 29.483 26.9914 28.5952 27.5846C27.7075 28.1778 26.6638 28.4944 25.5961 28.4944C24.1644 28.4944 22.7913 27.9256 21.779 26.9133C20.7666 25.9009 20.1979 24.5278 20.1979 23.0961Z"
        fill="#4C0055"
      />
      <rect x="0.5" y="0.5" width="52" height="47" rx="3.5" stroke="#4C0055" />
    </svg>
  );
};
