import React from "react";
import { useParams } from "react-router-dom";
import { FeatureTypes } from "../utilities/constants/global.constants";
import VehicleProfileDocuments from "./vehicle-profile/VehicleProfileDocuments";

const Documents = ({ type }) => {
  const params = useParams();
  const id = params.id;
  if (type === FeatureTypes.VehicleProfile) {
    return <VehicleProfileDocuments vehicleProfileId={id} />;
  }

  return <></>;
};
export default Documents;
