import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  BackIcon,
  DeleteMakeIcon,
  DocumentSucessIcon,
  PlusIcon,
} from "../../components/gogo-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  clearVehicleProfileData,
  deleteVehicleSpecArtifact,
  getArtifactsByProfileId,
  getVehicleProfileById,
  uploadVehicleProfileArtifact,
} from "../../redux/actions/vehicleAction";
import { useState } from "react";
import FileUpload from "../../components/common/file-upload";
import { Preview } from "../../components/common/preview";
import {
  FeatureTypes,
  ConfigurationKeys,
  IsFileValid,
  DocMimeTypes,
  getFileTypeMessageString,
} from "../../utilities/constants/global.constants";
import { fetchConfigurationValue } from "../../redux/actions/masterDataConfigurationAction";
import MessageBox, {
  MessageBoxResult,
  MessageBoxType,
} from "../../components/common/message-box";
import { toast } from "react-toastify";

const VehicleProfileDocuments = ({ vehicleProfileId }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [documentData, setDocumentData] = useState();
  const [previewData, setPreviewData] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [showPlaceholder, setShowPlaceholder] = useState(false);
  const [allowedFileSize, setAllowedFileSize] = useState([]);

  const languageId = useSelector(
    (state) => state.globalReducer.selectedLanguage
  );
  const vehicleProfile = useSelector(
    (state) => state.vehicleReducer.vehicleProfile
  );
  const artifactProfileData = useSelector(
    (state) => state.vehicleReducer.artifactProfileData
  );

  useEffect(() => {
    dispatch(getVehicleProfileById(vehicleProfileId, languageId));
    dispatch(
      getArtifactsByProfileId({
        languageId: languageId,
        id: vehicleProfileId,
        type: FeatureTypes.VehicleProfile,
      })
    );
    dispatch(
      fetchConfigurationValue(ConfigurationKeys.MaxDocumentSizeInMB, languageId)
    ).then((res) => {
      if (res) setAllowedFileSize(res);
    });
    return () => {
      dispatch(clearVehicleProfileData());
    };
  }, [dispatch, vehicleProfileId, languageId]);

  useEffect(() => {
    if (artifactProfileData?.length > 0) {
      setDocumentData(artifactProfileData);
    }
  }, [artifactProfileData]);

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleUploadDocument = async (file) => {
    const isValidData = await IsFileValid(file, {
      fileSize: allowedFileSize.ConfigurationValue,
      fileType: DocMimeTypes,
      checkFileSize: false,
    });
    if (!isValidData.isFileSizeValid) {
      await MessageBox.open({
        content: `${t("FILE_SIZE_ERROR_MSG", {
          size: allowedFileSize.ConfigurationValue,
        })}`,
        type: MessageBoxType.Message,
      });
    } else if (!isValidData.isFileTypeValid) {
      const fileType = getFileTypeMessageString(DocMimeTypes);
      await MessageBox.open({
        content: t("FILE_TYPE_ERROR_MSG", {
          fileTypes: fileType,
        }),
        type: MessageBoxType.Message,
      });
    } else {
      const imageName = file.name;
      const imageBase = await convertBase64(file);
      const payload = {
        ArtifactTypeId: 154,
        FileData: imageBase,
        FileName: imageName,
        Id: vehicleProfileId,
        SpecArtifactId: 0,
        Type: FeatureTypes.VehicleProfile,
      };
      setShowPlaceholder(true);
      dispatch(uploadVehicleProfileArtifact(payload))
        .then((res) => {
          if (res.data) {
            dispatch(
              getArtifactsByProfileId({
                languageId: languageId,
                id: vehicleProfileId,
                type: FeatureTypes.VehicleProfile,
              })
            );
            setShowPlaceholder(false);
            toast.success(t("DOCUMENT_UPLOAD_SUCCESS_MSG"));
          } else {
            setShowPlaceholder(false);
            toast.error(t("DOCUMENT_UPLOAD_ERROR_MSG"));
          }
        })
        .catch(() => {
          setShowPlaceholder(false);
          toast.error(t("DOCUMENT_UPLOAD_ERROR_MSG"));
        });
    }
  };

  const removeDoc = async (VehicleSpecArtifactId) => {
    const result = await MessageBox.open({
      content: t("DELETE_DOC_CONFIRM_MESSAGE"),
      type: MessageBoxType.Confirmation,
    });
    if (result === MessageBoxResult.Yes) {
      const data = {
        artifactId: VehicleSpecArtifactId,
        id: parseInt(vehicleProfileId),
        type: FeatureTypes.VehicleProfile,
      };

      dispatch(deleteVehicleSpecArtifact(data))
        .then((res) => {
          if (res.data.IsSuccess) {
            dispatch(
              getArtifactsByProfileId({
                languageId: languageId,
                id: vehicleProfileId,
                type: FeatureTypes.VehicleProfile,
              })
            );
            toast.success(t("DOCUMENT_DELETE_SUCCESS_MSG"));
          } else {
            toast.error(t("DOCUMENT_DELETE_ERROR_MSG"));
          }
        })
        .catch(() => {
          toast.error(t("DOCUMENT_DELETE_ERROR_MSG"));
        });
    }
  };

  const updateShowPreview = (url, mime) => {
    setPreviewData({
      previewUrl: url,
      previewMimeType: mime,
    });
    setShowPreview(true);
  };

  return (
    <div className="mt-1">
      <div className="container mx-auto">
        <div
          className="flex flex-row gap-2 items-center cursor-pointer"
          onClick={() => {
            history.goBack();
            dispatch(clearVehicleProfileData());
          }}
        >
          <BackIcon className="back-button arabic-back-icon" />
          <span className="page-header">{t("EDIT_DOCUMENTS")}</span>
        </div>
        <div className="bg-white my-4 px-8 py-4">
          {vehicleProfile && (
            <p className="page-header">
              {vehicleProfile?.Year +
                " - " +
                vehicleProfile?.Make +
                " - " +
                vehicleProfile?.Model +
                `${vehicleProfile?.Trim ? ` - ${vehicleProfile?.Trim}` : ""}` +
                `${
                  vehicleProfile?.ColorVariant
                    ? ` - ${vehicleProfile?.ColorVariant}`
                    : ""
                }`}
            </p>
          )}
          <div className="grid grid-cols-1 mt-6 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-5">
            {documentData?.length > 0 &&
              documentData.map((x, index) => {
                return x.ArtifactTypeKey === "mfg-document" &&
                  x.ArtifactUrlPath ? (
                  <div
                    className="border w-full aspect-4-3 border-dashed flex items-center justify-center"
                    key={index}
                  >
                    <div className="flex flex-col w-full gap-4 items-center justify-center px-3">
                      <DocumentSucessIcon className="w-6 h-6" />
                      <div className="flex w-full justify-between items-center">
                        <span
                          onClick={() =>
                            updateShowPreview(x.ArtifactUrlPath, x.MimeType)
                          }
                          className="cursor-pointer"
                        >
                          {x.ArtifactUrl}
                        </span>
                        <button onClick={() => removeDoc(x.SpecArtifactId)}>
                          <DeleteMakeIcon className="h-6 w-6" />
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <React.Fragment key={index} />
                );
              })}
            <FileUpload
              onChange={async (status, files) => {
                if (files && status === "UPLOADED") {
                  handleUploadDocument(files[0]);
                }
              }}
            >
              {(props) => {
                return (
                  <>
                    {showPlaceholder ? (
                      <div className="w-full aspect-4-3 border border-dashed flex items-center justify-center">
                        <div className="w-16 h-16 border-b-2 border-t-2 border-purple-600 rounded-full animate-spin"></div>
                      </div>
                    ) : (
                      <div
                        className="w-full aspect-4-3 border border-dashed flex items-center justify-center cursor-pointer"
                        onClick={() => props.handleFileUpload()}
                      >
                        <PlusIcon className="h-16 w-16" />
                      </div>
                    )}
                  </>
                );
              }}
            </FileUpload>
          </div>
        </div>
      </div>
      {showPreview && (
        <Preview
          showPopUp={showPreview}
          documentUrl={previewData?.previewUrl}
          closeModal={setShowPreview}
          mimeType={previewData?.previewMimeType}
        />
      )}
    </div>
  );
};
export default VehicleProfileDocuments;
