import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import format from "./i18n-format";
import { defaultLanguage, supportedLanguages } from "../../config/i18n";
import { I18N_NS } from "./i18n-labels";
import Backend from "i18next-http-backend";
i18next
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    supportedLngs: supportedLanguages,

    nonExplicitSupportedLngs: true,

    fallbackLng: defaultLanguage,

    interpolation: {
      escapeValue: false,
      format,
    },
    backend: {
      loadPath: `${process.env.REACT_APP_TRANSLATIONS}/{{lng}}Translation.Json`, // Azure Blob URL
    },

    debug: process.env.REACT_APP_I18NEXT_DEBUG === "local1",

    ns: [I18N_NS.DEFAULT, I18N_NS.MSG_CODE],

    defaultNS: [I18N_NS.DEFAULT],
    missingKeyNoValueFallbackToKey: true,
  });

export default i18next;

export function languageCodeOnly(fullyQualifiedCode) {
  return fullyQualifiedCode.split("-")[0];
}
