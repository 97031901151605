import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BackIcon } from "../../components/gogo-icons";
import VehicleSpecificationList from "../../components/vehicle-specification-list";
import { useDispatch, useSelector } from "react-redux";
import { clearTrimData, getTrimById } from "../../redux/actions/vehicleAction";

const VariantSpecifications = ({ trimId, handleSave, specifications }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const languageId = useSelector(
    (state) => state.globalReducer.selectedLanguage
  );

  const trimData = useSelector((state) => state.vehicleReducer.trimData);

  useEffect(() => {
    if (trimId) {
      dispatch(getTrimById(trimId));
    }
    return () => {
      dispatch(clearTrimData());
    };
  }, [dispatch, trimId]);

  const saveData = (selectedSpecs) => {
    handleSave(selectedSpecs);
  };

  const getVariantName = () => {
    let label;
    let labelData = trimData?.Values.filter(
      (itm) => itm.LanguageId === languageId
    );
    label =
      labelData?.length > 0 ? labelData[0].Label : trimData?.Values[0].Label;
    return label;
  };

  return (
    <div className="mt-1">
      <div className="container mx-auto">
        <div
          className="flex flex-row gap-2 items-center cursor-pointer"
          onClick={() => {
            history.goBack();
          }}
        >
          <BackIcon className="back-button arabic-back-icon" />
          <span className="page-header">{t("EDIT_TRIM_SPECS")}</span>
        </div>
        <div className="bg-white my-4 px-8 py-6">
          {trimData?.Values && (
            <div className="flex gap-1">
              <p className="page-header">{t("TRIM")} -</p>
              <p className="page-header">{getVariantName()}</p>
            </div>
          )}
          <VehicleSpecificationList
            specifications={specifications}
            saveData={saveData}
          />
        </div>
      </div>
    </div>
  );
};
export default VariantSpecifications;
