import {
  SET_WARRANTY_VEHICLE_DATA,
  GET_VEHICLE_MAKE,
  GET_VEHICLE_MODEL,
  GET_VEHICLE_TRIM,
  OPEN_WARRANTY_POP_UP,
  SET_STANDALONE_WARRANTY_VEHICLE_DATA,
  GET_GLOBAL_DATA_VAS_CITIES,
} from "../types";

const initialState = {
  vehicleData: [],
  vehicleListingId: null,
  vehicleMakeData: [],
  vehicleModelData: [],
  vehicleTrimData: [],
  isOpen: false,
  standAloneVehicleData: {},
  globalDataVASCities: [],
};

export const vasReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WARRANTY_VEHICLE_DATA:
      return {
        ...state,
        vehicleData: action.payload,
      };
    case GET_VEHICLE_MAKE:
      return {
        ...state,
        vehicleMakeData: action.payload,
      };
    case GET_VEHICLE_MODEL:
      return {
        ...state,
        vehicleModelData: action.payload,
      };
    case GET_VEHICLE_TRIM:
      return {
        ...state,
        vehicleTrimData: action.payload,
      };
    case OPEN_WARRANTY_POP_UP:
      return {
        ...state,
        isOpen: action.payload,
      };
    case SET_STANDALONE_WARRANTY_VEHICLE_DATA:
      return {
        ...state,
        standAloneVehicleData: action.payload,
      };
    case GET_GLOBAL_DATA_VAS_CITIES:
      return {
        ...state,
        globalDataVASCities: action.payload,
      };
    default:
      return state;
  }
};
